<template>
  <div class="form__group js-acc-item">
    <div class="form__group-header js-acc-title-booking" @click="togglePayment">
      <div class="form__group-icon">
        <SvgIcon :name="icon"/>
      </div>
      <div class="form__group-title">{{title}}</div>
      <div class="form__group-arrow">
        <SvgIcon name="down-arrow"/>
      </div>
    </div>
    <div class="form__group-content js-acc-content-booking">
      <div class="form__group-content-inner">
        <label class="form__label form__label--text">
          <div class="form__caption">Airport name</div>
          <div class="form__field-wrapper">
            <input
              class="form__field"
              type="text"
              name="-airport-name"
              placeholder="Fill in Airport Name"
              autocomplete="off"
              required="required"
            />
          </div>
        </label>
        <label class="form__label form__label--text">
          <div class="form__caption">Date and Estimated Time of Departure</div>
          <div class="form__field-wrapper">
            <input
              class="form__field"
              type="text"
              name="date-and-time"
              placeholder="Fill in Date and Estimated Time of Departure (ETD)"
              autocomplete="off"
              required="required"
            />
          </div>
        </label>
        <label class="form__label form__label--text">
          <div class="form__caption">Aircraft provider</div>
          <div class="form__field-wrapper">
            <input
              class="form__field"
              type="text"
              name="aircraft-provider"
              placeholder="Fill in Aircraft Provider Name"
              autocomplete="off"
              required="required"
            />
          </div>
        </label>
        <label class="form__label form__label--text">
          <div class="form__caption">Aircraft</div>
          <div class="form__field-wrapper">
            <input
              class="form__field"
              type="text"
              name="aircraft"
              placeholder="Fill in Aircraft"
              autocomplete="off"
              required="required"
            />
          </div>
        </label>
        <div class="form__labelGroup">
          <label class="form__label form__label--text">
            <div class="form__caption">Distance</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                type="text"
                name="distance"
                placeholder="Type here"
                autocomplete="off"
                required="required"
              />
            </div>
          </label>
          <label class="form__label form__label--text">
            <div class="form__caption">Duration</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                type="text"
                name="-duration"
                placeholder="Type here"
                autocomplete="off"
                required="required"
              />
            </div>
          </label>
          <label class="form__label form__label--text">
            <div class="form__caption">Max passengers</div>
            <div class="form__field-wrapper">
              <input
                class="form__field"
                type="text"
                name="max-pas"
                placeholder="Type here"
                autocomplete="off"
                required="required"
              />
            </div>
          </label>
        </div>
        <a class="form__link" href="#">
          <span class="text">photos &#38; details</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['icon', 'title'],
    name: 'ManualBookingForm',
    methods: {
      togglePayment (event) {
        const item = event.currentTarget.closest('.js-acc-item').querySelector('.js-acc-content-booking')
        event.currentTarget.closest('.js-acc-item').classList.toggle('mutation')
        document.querySelectorAll('.js-acc-content-booking').forEach((content) => {
          if (item !== content) {
            content.querySelector('.form__group-content-inner').style.opacity = 0
            content.closest('.js-acc-item').classList.remove('mutation')
            this.$root.animations.slideUp(content)
          }
        })

        this.$root.animations.slideToggle(item)
        const innerContent = item.querySelector('.form__group-content-inner')

        if (window.getComputedStyle(innerContent).opacity === '1') {
          innerContent.style.opacity = 0
        } else {
          item.addEventListener('transitionend', () => {
            if (window.getComputedStyle(innerContent).opacity === '0') {
              innerContent.style.opacity = 1
            }
          }, { once: true })
        }
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
