<template>
  <vue-scroll :ops="ops" :style="{width: '100%'}" class="bookingScrollWrap">
    <div class="bookingDashboard bookingDashboard--admin bookingDashboard--confirm">
      <div class="bookingDashboard__nav">
        <div class="bookingDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Aircraft N566KL</span>
        </div>
        <div class="bookingDashboard__nav-item js-tabNavItem" @click="tabActivate">
          <span class="text">Aircraft N123JLF</span>
        </div>
      </div>
      <div class="bookingDashboard__content">

        <div class="bookingDashboard__content-item js-tabContentItem">
          <form class="form form--bookingManual">
            <div class="form__column">
              <ManualBookingForm :icon="'departures'" :title="'Outbound departure'"/>
              <ManualBookingForm :icon="'departures'" :title="'Inbound departure'"/>
            </div>
            <div class="form__column">
              <ManualBookingForm :icon="'arrival'" :title="'Outbound arrival'"/>
              <ManualBookingForm :icon="'arrival'" :title="'Inbound arrival'"/>
            </div>
            <label class="form__label form__label--price price">
              <div class="form__caption">Price and/or price per seat:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  name="price-per-seat"
                  v-money="money"
                  placeholder="Enter price in $"
                  autocomplete="off"
                  required="required"
                />
              </div>
            </label>
            <div class="form__label form__label--button">
              <button class="form__button form__button--black" type="button">
                <span class="text">Add all trip/aircraft options and send to flyer</span>
              </button>
              <button class="form__button form__button--green">
                <span class="text">Send chosen trip/aircraft and book</span>
              </button>
            </div>
          </form>
        </div>

        <div class="bookingDashboard__content-item js-tabContentItem">
          <form class="form form--bookingManual">
            <div class="form__column">
              <ManualBookingForm :icon="'departures'" :title="'Outbound departure'"/>
              <ManualBookingForm :icon="'departures'" :title="'Inbound departure'"/>
            </div>
            <div class="form__column">
              <ManualBookingForm :icon="'arrival'" :title="'Outbound arrival'"/>
              <ManualBookingForm :icon="'arrival'" :title="'Inbound arrival'"/>
            </div>
            <label class="form__label form__label--price price">
              <div class="form__caption">Price and/or price per seat:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  name="price-per-seat"
                  v-money="money"
                  placeholder="Enter price in $"
                  autocomplete="off"
                  required="required"
                />
              </div>
            </label>
            <div class="form__label form__label--button">
              <button class="form__button form__button--black" type="button">
                <span class="text">Add all trip/aircraft options and send to flyer</span>
              </button>
              <button class="form__button form__button--green">
                <span class="text">Send chosen trip/aircraft and book</span>
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </vue-scroll>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import ManualBookingForm from '@/components/dashboard/ManualBookingForm/ManualBookingForm'
  import { VMoney } from 'v-money'
  import './ManualBooking.styl'

  export default {
    name: 'ManualBooking',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: true,
      },
    }),
    directives: { money: VMoney },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
    },
    components: {
      ManualBookingForm,
    },
  }
</script>
